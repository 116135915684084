<template>

  <div class="px-10">

    <div class="text-h4 primary--text">Soon</div>
    
    <v-toolbar dense flat rounded outlined class="my-5 pt-3 pb-15">
      
      <div style="width: 200px;">
      <v-text-field label="วันที่" prepend-inner-icon="mdi-calendar" outlined rounded dense hide-details></v-text-field>
      </div>

      <div style="width: 200px;" class="mx-10">
      <v-text-field label="ถึงวันที่" prepend-inner-icon="mdi-calendar" outlined rounded dense hide-details></v-text-field>
      </div>
      
      <v-btn color="primary" outlined small class="ml-5 px-10">ค้นหา</v-btn>

      <v-spacer></v-spacer>

    </v-toolbar>

    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="list"
      :search="search"
      :loading="loading"
      sort-by="col_a"
      :loading-text="$table_loading_text"

      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event"
    >

      <template v-slot:footer>
        <table-pagination :page.sync="page" :itemsPerPage.sync="itemsPerPage" :length="pageCount"/>
      </template>

    </v-data-table>

  </div>

</template>

<script>

  export default {
    data: () => ({
      search: '',
      selected: [],
      loading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      list: [],
      headers: [
        { text: 'วันที่', value: 'col_a'},
        { text: 'เลขที่เอกสาร', value: 'col_b', filterable: false },
        { text: 'รอบภาษี', value: 'col_c'},
        { text: 'จำนวน (คัน)', value: 'col_d', filterable: false},
        { text: 'สาขา', value: 'col_e', filterable: false },
        { text: 'รวมราคาขนส่งเก็บ', value: 'col_f', filterable: false },
      ],

    }),

    created () {
      this.getData()
    },

    methods: {
      getData () {
        this.loading = false;
        this.list = [
          {
            id: 1,
            col_a: 'a',
            col_b: 'b',
            col_c: 'c',
            col_d: 'd',
            col_e: 'e',
            col_f: 'f',
          },
          {
            id: 2,
            col_a: 'a',
            col_b: 'b',
            col_c: 'c',
            col_d: 'd',
            col_e: 'e',
            col_f: 'f',
          },
        ]
      },
    }
  }
</script>